header {
  background: var(--c2);
}

.header-content {
  display: flex;
  padding: 30px 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  text-align: center;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  color: var(--c5);
}

header h1 {
  font-size: 2.25em;
  font-weight: 600;
  margin: 0 auto;
  padding-right: 20px;
}

header h1 a {
  color: inherit;
  text-decoration: none;
}

.header-content form {
  margin: 0 auto;
}

.header-content form input {
  padding: 10px 15px;
  font-family: "Ibarra Real Nova", serif;
  background-color: var(--c1);
  color: var(--c5);
  border: none;
  font-size: 1.125em;
  transition: all 0.2s ease;
}

.header-content form input:nth-child(2) {
  min-width: unset;
}

.header-content form input:nth-child(1)::placeholder {
  color: var(--c4);
}

.header-content form input:nth-child(2):hover {
  background-color: var(--c3);
  color: var(--c1);
  cursor: pointer;
}

nav {
  margin: 0 auto;
}

nav ul {
  display: flex;
  gap: 10px;
  justify-self: center;
}

nav ul li a {
  font-size: 1.125em;
  padding: 10px 20px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

nav ul li a:hover {
  background-color: var(--c1);
}

nav ul li {
  position: relative;
  z-index: 2;
}

nav ul li:hover a {
  background-color: var(--c1);
}

nav ul li ul {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
  position: absolute;
  right: 0;
  top: 30px;
  min-width: 160px;
  flex-direction: column;
  text-align: right;
  background-color: var(--c1);
}

nav ul li:nth-child(1) ul {
  right: unset;
  left: 0;
  min-width: 200px;
  text-align: left;
}

nav ul li:nth-child(2) ul {
  min-width: 200px;
}

nav ul li:hover ul {
  visibility: visible;
  opacity: 1;
}

nav ul li ul li {
  margin: 25px 15px 0px 30px;
}

nav ul li:nth-child(1) ul li {
  margin: 25px 30px 0px 15px;
}

nav ul li ul li:last-child {
  margin-bottom: 25px;
}

nav ul li ul li a:hover {
  background-color: var(--c2);
}
