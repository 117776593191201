main {
  background: var(--c1);
  width: 100%;
  padding-top: 24px;
}

.services{
  background: var(--c5);
}

.services-inner{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 40px;
  max-width: 1140px;
  margin: 0 auto;
  gap: 60px;
}

.service-item{
  background: var(--c2);
  color: var(--c5);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2), 2px 2px 10px rgba(0, 0, 0, 0.25);
  min-width: 300px;
  text-align: center;
  border-radius: 10px;
}

.item-category{
  font-family: "Roboto Slab", sans-serif;
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  padding: 8px;
  margin-top: 30px;
  background: var(--c3);
  color: var(--c5);
  display: inline-block;
}

.icon{
  width: 100px;
  display: block;
  margin: 25px auto 0 auto;
  border-radius: 50%;
}

.item-name{
  font-size: 2em;
  padding: 20px 0;
}

.item-contact{
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.item-contact a:first-child img{
  width: 30px;
}

.not-found {
  text-align: center;
  color: var(--c4);
  font-size: 40px;
  margin-block: 72px;
}