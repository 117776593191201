#main {
  background-color: var(--c2);
}

.main-content {
  max-width: 1140px;
  padding: 40px 40px 60px 40px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  color: var(--c5);
}

.main-content .content {
  text-align: center;
  align-self: end;
  padding-bottom: 120px;
  max-width: 451px;
}

.content span {
  font-family: "Great Vibes", cursive;
  font-size: 2.25em;
  color: var(--c4);
}

.content h1 {
  font-size: 4em;
  font-weight: 700;
}

.main-content .content p {
  text-align: left;
}

.content p {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.25em;
  padding: 20px 0;
  color: var(--c4);
}

.content a {
  background: linear-gradient(var(--c3), #aa5928);
  display: inline-block;
  border-radius: 3px;
  margin-top: 8px;
  padding: 15px 30px;
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.125em;
  line-height: 1.25em;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--c5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.content a:hover {
  background: linear-gradient(#ff8438, #cd6c2f);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.picture {
  border-radius: 5px;
  justify-self: right;
  width: 100%;
  height: 480px;
  max-width: 560px;
}

.picture img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 480px;
  display: inline-block;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
  border-radius: 5px;
}

.businesses-content .content p {
  text-align: center;
}

@media (max-width: 722px) {
  .main-content {
    grid-template-columns: 1fr;
    justify-items: center;
    padding-bottom: 80px;
  }
  .main-content .content {
    padding-bottom: unset;
  }
  .picture {
    margin: 0 auto;
  }
  .main-content .content p {
    text-align: center;
  }
}

#businesses {
  background: var(--c5);
  position: relative;
}

#businesses > img {
  position: absolute;
  max-width: 500px;
  left: -1px;
  top: -1px;
}

.businesses-content {
  max-width: 1140px;
  padding: 140px 40px 0 40px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;
}

.businesses-content .content {
  text-align: center;
  color: var(--c1);
  justify-self: end;
  max-width: 500px;
}

.businesses-content .content span {
  color: var(--c2);
}

.businesses-content .content h1 {
  padding-bottom: 15px;
}

.businesses-content p {
  text-align: right;
  color: var(--c2);
  padding: 15px 0 0 0;
}

.businesses-content > div:nth-child(1) {
  order: 2;
}

.links img {
  max-width: 600px;
  display: block;
}

.links {
  position: relative;
}

.links a {
  position: absolute;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 700;
  color: var(--c2);
  font-size: 1.6em;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.15s ease;
}

.links a:hover {
  color: var(--c1);
  cursor: pointer;
}

.links img:nth-child(2) {
  display: none;
}

.links a:nth-child(3) {
  top: 78px;
  left: 86px;
  transform: rotate(1.7deg);
}

.links a:nth-child(4) {
  left: 115px;
  top: 168px;
  transform: rotate(-1.8deg);
}

.links a:nth-child(5) {
  left: 135px;
  top: 257px;
}

.links a:nth-child(6) {
  left: 136px;
  top: 347px;
  transform: rotate(-0.5deg);
}

.links a:nth-child(7) {
  left: 127px;
  top: 439px;
  transform: rotate(-0.3deg);
}

.links a:nth-child(8) {
  left: 92px;
  top: 526px;
  transform: rotate(-1deg);
}

.links a:nth-child(9) {
  font-size: 1.3em;
  left: 340px;
  top: 278px;
  transform: rotate(-13deg);
}

.links a:nth-child(10) {
  font-size: 1.3em;
  left: 362px;
  top: 349px;
  transform: rotate(-10deg);
}

.links a:nth-child(11) {
  font-size: 1.3em;
  left: 382px;
  top: 416px;
  transform: rotate(-12deg);
}

.links a:nth-child(12) {
  font-size: 1.3em;
  left: 416px;
  top: 487px;
  transform: rotate(-11.2deg);
}

.links a:nth-child(13) {
  font-size: 1.3em;
  left: 410px;
  top: 558px;
  transform: rotate(-11.5deg);
}

.transportation {
  background: var(--c5);
}

.transportation-inner {
  max-width: 1140px;
  padding: 120px 40px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 40px;
  color: var(--c1);
  text-align: center;
}

.transportation-inner .content {
  max-width: 500px;
}

.transportation-inner .content span,
.transportation-inner .content p {
  color: var(--c2);
}

.transportation-inner .content p:nth-child(3) {
  margin-top: 5px;
  line-height: 1.25em;
}

.transportation-inner .content p:nth-child(5) {
  padding-top: 0;
}

.transportation-inner .content p:nth-child(4),
.transportation-inner .content p:nth-child(6) {
  font-size: 1.75em;
  font-weight: 600;
  padding-top: 0;
}

.map {
  height: 420px;
  width: 100%;
  max-width: 640px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 970px) {
  .businesses-content {
    padding-top: 100px;
  }
  #businesses > img {
    max-width: 340px;
  }
  .businesses-content > div:nth-child(1) {
    order: unset;
  }
  .businesses-content {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .transportation-inner {
    grid-template-columns: 1fr;
  }
  .businesses-content .content {
    justify-self: center;
  }
  .businesses-content p {
    text-align: center;
  }
}

@media (max-width: 722px) {
  #businesses > img {
    display: none;
  }
  .links img:nth-child(1) {
    display: none;
  }
  .links img:nth-child(2) {
    display: block;
    max-width: 300px;
  }
  .links a:nth-child(3) {
    top: 60px;
    left: 63px;
  }

  .links a:nth-child(4) {
    left: 85px;
    top: 129px;
  }

  .links a:nth-child(5) {
    left: 100px;
    top: 197px;
  }

  .links a:nth-child(6) {
    left: 100px;
    top: 266px;
  }

  .links a:nth-child(7) {
    left: 92px;
    top: 336px;
  }

  .links a:nth-child(8) {
    font-size: 1.25em;
    left: 67px;
    top: 402px;
    transform: rotate(0.5deg);
  }

  .links a:nth-child(9) {
    font-size: 1.25em;
    left: 78px;
    top: 472px;
    transform: rotate(1.5deg);
  }

  .links a:nth-child(10) {
    font-size: 1.25em;
    left: 90px;
    top: 541px;
    transform: rotate(-1.5deg);
  }

  .links a:nth-child(11) {
    font-size: 1.25em;
    left: 92px;
    top: 610px;
    transform: rotate(0.5deg);
  }

  .links a:nth-child(12) {
    font-size: 1.25em;
    left: 110px;
    top: 678px;
    transform: rotate(-0.5deg);
  }
  .links a:nth-child(13) {
    font-size: 1.25em;
    left: 100px;
    top: 748px;
    transform: rotate(-0.5deg);
  }
  .links a {
    font-size: 1.25em;
  }
}

#form {
  background: var(--c2);
}

.form-content {
  max-width: 1140px;
  padding: 120px 20px;
  margin: 0 auto;
}

.form-content h1 {
  color: var(--c5);
  font-size: 3em;
  font-weight: 700;
  text-align: center;
}

.form-content p {
  display: block;
  margin: 0 auto;
  max-width: 600px;
  color: var(--c4);
  text-align: center;
  font-size: 1.5em;
  line-height: 1.25em;
  padding-top: 20px;
}

.form {
  margin: 60px auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.form-left {
  background: var(--c5);
  padding: 40px;
  border-radius: 15px 0 0 15px;
  font-size: 1.125em;
}

.form-right {
  background: var(--c1);
  padding: 40px;
  border-radius: 0 15px 15px 0;
}

.form-left h4 {
  font-size: 1.5em;
  font-weight: 600;
}

.form-left > div:nth-child(2) {
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 30px 5px;
  margin-top: 30px;
}

.form-left > div:nth-child(2) > img:nth-child(3) {
  padding-top: 2px;
}

.form-left address {
  line-height: 1.25em;
}

.form-left a {
  text-decoration: none;
  color: inherit;
}

.social {
  display: flex;
  gap: 15px;
  margin: 30px 0 0 23px;
}

.form-right form label {
  display: block;
  color: var(--c5);
  font-size: 1.125em;
}

.form-right input,
.form-right select,
.form-right textarea {
  font-family: "Ibarra Real Nova", serif;
  background: var(--c2);
  box-sizing: border-box;
  width: 100%;
  color: var(--c5);
  border: none;
  padding: 12px;
  font-size: 1.125em;
  margin-top: 5px;
}

input::placeholder {
  font-family: "Ibarra Real Nova", serif;
}

.form-right form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.form-right form div span {
  font-size: 1.125em;
  display: inline-block;
  padding: 5px 0;
  color: var(--c5);
}

.form-button {
  font-family: "Roboto Slab", sans-serif !important;
  text-transform: uppercase;
  max-width: 120px;
  min-height: 51px;
  background: var(--c3) !important;
  cursor: pointer;
}

.term-check {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  align-self: flex-end;
  background: var(--c2);
  padding: 12px;
  gap: 20px;
}

.term-check label {
  line-height: 1.125em;
}

.term-check a {
  text-decoration: underline;
  cursor: pointer;
}

.term-check input {
  transform: scale(1.5);
  max-width: 15px;
}

::-webkit-file-upload-button {
  font-family: "Ibarra Real Nova";
  font-weight: 700;
  background: var(--c1);
  color: var(--c5);
  padding: 10px;
  border: none;
  margin-right: 10px;
}

@media (max-width: 970px) {
  .form {
    grid-template-columns: 1fr;
  }
  .form-left {
    border-radius: 15px 15px 0 0;
    display: flex;
    gap: 40px 60px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .form-left div:nth-child(2),
  .form-left .social {
    margin: 0;
  }
  .form-right {
    border-radius: 0 0 15px 15px;
  }
}

@media (max-width: 722px) {
  .form-right form {
    grid-template-columns: 1fr;
  }
}
